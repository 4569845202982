import { FormPropsType } from '../types/FormPropType/FormPropType';

export const validationMesAdmission = (values: FormPropsType): boolean => {
  const monthAdmission = Number(values.mesAnoAdmissao.substring(0, 2));
  const yearOfAdmission = Number(values.mesAnoAdmissao.substring(2, 6));

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const mininumYear = 1900;

  if (
    yearOfAdmission < mininumYear ||
    monthAdmission <= 0 ||
    monthAdmission > 12 ||
    yearOfAdmission > currentYear ||
    (yearOfAdmission === currentYear && monthAdmission > currentMonth)
  ) {
    return false;
  }

  return true;
};
